import React from 'react'
import { Link, graphql } from 'gatsby'

import Box from '../../styled/Box'
import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import Posts from '../../components/Posts'
import SEO from '../../components/SEO'

function BlogPage(props = {}) {
  const posts = props.data.allMdx.edges
  return (
    <Layout>
      <SEO title="Blog" />
      <PageHeader
        title="Blog"
        description="On design, development, productivity, leadership, and more."
      />
      <Box mb="l">
        <Posts posts={posts} />
        {posts.length > 10 && (
          <Box as={Link} to="/blog/archive">
            View older posts
          </Box>
        )}
      </Box>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
      limit: 10
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            tags
          }
        }
      }
    }
  }
`

export default BlogPage
